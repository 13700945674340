import React from 'react';
import { Container } from '@mui/material';

function Contact() {
  return (
    <Container>
      <h2>worf@riderz.io</h2>
    </Container>
  );
}

export default Contact;
